<template>
<div class="form-fill-page">
  <div class="__wrapper">
    <div class="page__header"></div>

    <div class="page__body" v-if="!loading && Object.keys(formData).length">
      <div class="letwecare-form">
        <div class="form__header">
          <div class="header-title">{{ formData.form.name }}</div>
          <div class="header-description" v-if="formData.updated_datetime">上次更新時間：{{ formData.updated_datetime }}</div>
        </div>
        <div class="form__body">
          <a-form>
            <div
              class="form-section"
              v-for="(form, formIdx) in formData.form_list"
            >
              <div class="section-title">{{ form.section_title }}</div>

              <div
                class="section-fields"
                v-for="(field, fieldIdx) in form.fields"
              >
                <a-row>
                  <a-col :span="24">
                    <a-form-item
                      :label="field.name"
                      :required="field.required"
                    >
                      <template v-if="field.type == 'number'">
                        <a-input-number
                          v-model:value="field.value"
                          :disabled="submitting"
                        />
                      </template>

                      <template v-if="field.type == 'text-field'">
                        <a-input
                          v-model:value="field.value"
                          :disabled="submitting"
                        />
                      </template>

                      <template v-if="field.type == 'textarea'">
                        <a-textarea
                          v-model:value="field.value"
                          :auto-size="{ minRows: 4 }"
                          :disabled="submitting"
                        />
                      </template>

                      <template v-if="field.type == 'radio'">
                        <a-radio-group v-model:value="field.value">
                          <a-radio  
                            v-for="opt in field.selection_items"
                            :value="opt.label"
                          >{{ opt.label }}</a-radio>
                        </a-radio-group>
                      </template>

                      <template v-if="field.type == 'checkbox'">
                        <a-checkbox-group v-model:value="field.value">
                          <a-checkbox  
                            v-for="opt in field.selection_items"
                            :value="opt.label"
                          >{{ opt.label }}</a-checkbox>
                        </a-checkbox-group>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>

              <a-divider v-if="formIdx < formData.form_list.length-1" />
            </div>
          </a-form>
        </div>
      </div>
    </div>

    <div class="page__actions" v-if="!loading && Object.keys(formData).length">
      <div align="center">
        <a-button class="action-btn" type="primary" size="large" @click="updateActivedFormContents">完成送出</a-button>
      </div>
    </div>

    <template v-if="loading">
      <div align="center" :style="{ padding: '200px 0' }">
        <a-spin tip="載入資料中..." :style="{ margin: '0 auto' }" />
      </div>
    </template>

    <div class="page__footer">
      <div align="center" :style="{ padding: '40px 0' }">
        copyright &copy; {{ new Date().getFullYear() }} 老玩客運動
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Loading */
    const loading = ref(false);

    /* Data */
    const formData = ref({});

    /* Api */
    const getActivedFormData = (async () => {
      const response = await api.v1.form.findOneActivedForm(route.params.code);
      formData.value = response.data.result;

      formData.value.form_list.forEach(section => {
        let fields = section.fields;
        fields.forEach(field => {
          if (!Object.keys(field).includes('value')) {
            field.value = '';
          }
        });
      });

      console.log(formData.value);
    });

    const updateActivedFormContents = (async () => {
      try {
        await api.v1.form.updateOneActivedFormContents(route.params.code, {
          form_list: formData.value.form_list
        });
        message.success('更新表單內容成功');
      } catch (error) {
        console.log(error);
        message.error('更新表單內容發生錯誤，請稍後再試');
      }
    });

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await getActivedFormData();

      loading.value = false;
    });
    
    return {
      route,

      /* Loading */
      loading,

      /* Data */
      formData,

      /* Api */
      updateActivedFormContents
    }
  },
  methods: {
    
  }
})
</script>

<style lang="scss">
.form-fill-page {
  .__wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

  }

  .page__body {
    padding: 36px 0 12px 0;
  }

  .page__actions {
    padding: 40px 0;

    .action-btn {
      height: 45px;
      width: 200px;
      font-size: 1.15rem;
    }
  }

  .page__footer {
    font-weight: 500;
    font-size: 0.8rem !important;
    letter-spacing: 0.05rem;
    color: #424242 !important;
  }
}
</style>